import React, { useState, useEffect } from 'react'
import { Link, navigate } from "gatsby"
import { isSafari } from "react-device-detect";
import { changeLocale, useIntl } from "gatsby-plugin-intl"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import CrossIcon from 'src/icons/cross.svg';
import MenuIcon from 'src/icons/menu.svg';
import InkaIcon from 'src/icons/inka.svg'
//@ts-ignore
import * as Styles from './NavBar.module.scss'

const NarBar = () => {
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const [showMenu, setShowMenu] = useState(false)
    useEffect(() => {
        document.querySelector('html')!.style.overflow = showMenu ? 'hidden' : 'auto'
    }, [showMenu])


    useEffect(() => {
        if (!breakpoints.sm)
            setShowMenu(false)
    }, [breakpoints.sm])




    const Logo = () => <Link
        to="/"
    >
        <span className={Styles.logoBox}>
            <InkaIcon />
            <div className={Styles.divider} />
            {
                intl.locale === 'en' && <div
                    style={{
                        fontFamily: isSafari ? 'Rubik' : 'Lato',
                        whiteSpace: 'nowrap',
                        minWidth: breakpoints.sm ? 'unset' : '200px',
                        fontSize: breakpoints.sm ? '11px' : 'unset'
                    }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'professional of' }) }}
                />
            }
            {
                intl.locale === 'zh' &&
                <div className={Styles.chinese}>
                    <h3 lang={intl.locale}>
                        {intl.formatMessage({ id: 'company name' })}
                    </h3>
                    <div
                        lang={intl.locale}
                        className={Styles.detail}
                    >{intl.formatMessage({ id: 'professional of' })}</div>
                </div>
            }
        </span>
    </Link>

    return (

        <div lang={intl.locale} className={Styles.main}>
            <div className={Styles.content}>
                <Logo />
                {
                    breakpoints.sm ? <div
                        onClick={() => setShowMenu(true)}
                    >
                        <MenuIcon />
                    </div> : <span className={Styles.buttonBar}>
                            <Link
                                to="/"
                                activeStyle={{
                                    color: '#7f1009'
                                }}
                            >
                                {intl.formatMessage({ id: 'HOME' })}
                            </Link>
                            <Link
                                to="/project"
                                activeStyle={{
                                    color: '#7f1009'
                                }}
                            >{intl.formatMessage({ id: 'PROJECT' })}
                            </Link>

                            <span
                                onClick={() => changeLocale(intl.locale === 'en' ? "zh" : 'en')}
                            >{intl.locale === 'en' ? '中文' : `English`}</span>

                        </span>
                }
            </div>
            {
                showMenu && <div className={Styles.menu}>
                    <div className={Styles.top}>
                        <Logo />
                        <div onClick={() => setShowMenu(() => false)}>
                            <CrossIcon onClick={() => setShowMenu(() => false)} />
                        </div>
                    </div>
                    <div lang={intl.locale} className={Styles.middle}>
                        <Link
                            to="/"
                            activeStyle={{
                                color: '#7f1009'
                            }}
                        >
                            {intl.formatMessage({ id: 'HOME' })}
                        </Link>
                        <div className={Styles.divider} />
                        <Link
                            to="/project"
                            activeStyle={{
                                color: '#7f1009'
                            }}
                        >{intl.formatMessage({ id: 'PROJECT' })}
                        </Link>
                        <div className={Styles.divider} />
                        <span
                            onClick={() => changeLocale(intl.locale === 'en' ? "zh" : 'en')}
                        >{intl.locale === 'en' ? '中文' : `English`}</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default NarBar
