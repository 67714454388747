// extracted by mini-css-extract-plugin
export var main = "NavBar-module--main--2BC9Z";
export var chinese = "NavBar-module--chinese--12wG3";
export var detail = "NavBar-module--detail--1Q5Jb";
export var content = "NavBar-module--content--ePwwK";
export var buttonBar = "NavBar-module--buttonBar--luQ4y";
export var menu = "NavBar-module--menu--1WvFF";
export var top = "NavBar-module--top--2b12H";
export var middle = "NavBar-module--middle--2VUya";
export var divider = "NavBar-module--divider--3DLFW";
export var pro = "NavBar-module--pro--33__w";
export var logoBox = "NavBar-module--logoBox--3D9oe";
export var logo = "NavBar-module--logo--WCXuY";