import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useIntl } from "gatsby-plugin-intl"
import * as _ from 'lodash'
import Classnames from 'classnames'
import { projects } from 'src/projectData.store'
import MoreIcon from 'src/icons/more.svg'
//@ts-ignore
import * as Styles from './ProjectHighlighDesktop.module.scss'
import YearBox from './YearBox'

const Project = ({ projectPage }: {
    projectPage?: boolean
}) => {
    const breakpoint = useBreakpoint()
    const intl = useIntl()
    const featured = projects.filter(item => item.feature)
    return (
        <div
            lang={intl.locale}
            className={Styles.main}>
            {
                projectPage && <div className={Styles.skew} />
            }
            {
                projectPage && <div className={Styles.skewBottom} />
            }
            <div className={Styles.content}>
                <div className={Styles.left}>
                    <MoreIcon />
                    <div className={Styles.inner}>
                        <div>
                            {
                                projectPage ? <h2>
                                    {intl.formatMessage({ id: 'Our Projects' })}
                                </h2> : <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Project Highlight' }) }} />
                            }
                            {
                                !projectPage && <a
                                    href={'/project'}
                                ><div className={Styles.our}>
                                        <p>{intl.formatMessage({ id: 'Our Projects' })}</p>
                                        <MoreIcon />
                                    </div></a>
                            }
                        </div>
                        <a href={`/project-detail/?projectId=${_.nth(featured, 1)?.projectId}`}>
                            <img src={_.head(_.nth(featured, 1)?.imsrc)} />
                            <div className={Styles.textBox}>
                                <h4 lang={intl.locale}>
                                    {intl.formatMessage({ id: _.nth(featured, 1)?.nameId })}
                                    <YearBox period={intl.formatMessage({ id: _.nth(featured, 1)?.periodId })} />
                                </h4>
                                {
                                    !breakpoint.md && <div className={Styles.detail}>
                                        {intl.formatMessage({ id: _.nth(featured, 1)?.workId })}
                                    </div>
                                }

                            </div>
                        </a>
                        <div />
                    </div>
                </div>
                <div className={Styles.right}>
                    <a href={`/project-detail/?projectId=${_.head(featured)?.projectId}`}>
                        <div className={Classnames(Styles.topRight, {
                            [Styles.projectTopRight]: projectPage
                        })}>
                            <img src={_.head(_.head(featured)?.imsrc)} />
                            <div className={Styles.textBox}>
                                <h4>
                                    {intl.formatMessage({ id: _.nth(featured, 0)?.nameId })}
                                    <YearBox period={intl.formatMessage({ id: _.nth(featured, 0)?.periodId })} />
                                </h4>
                                {
                                    !breakpoint.md && <div className={Styles.detail}>
                                        {intl.formatMessage({ id: _.nth(featured, 0)?.workId })}
                                    </div>
                                }
                            </div>
                        </div>
                    </a>
                    <a href={`/project-detail/?projectId=${_.nth(featured, 2)?.projectId}`}>
                        <div className={Styles.bottomRight}>
                            <img src={_.head(_.nth(featured, 2)?.imsrc)} />
                            <div className={Styles.textBox}>
                                <h4 lang={intl.locale}>
                                    {intl.formatMessage({ id: _.nth(featured, 2)?.nameId })}
                                    <YearBox period={intl.formatMessage({ id: _.nth(featured, 2)?.periodId })} />
                                </h4>
                                {
                                    !breakpoint.md && <div className={Styles.detail} lang={intl.locale}>
                                        {intl.formatMessage({ id: _.nth(featured, 2)?.workId })}
                                    </div>
                                }
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Project
