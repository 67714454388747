// extracted by mini-css-extract-plugin
export var main = "ProjectHighlighDesktop-module--main--1Wl_W";
export var skew = "ProjectHighlighDesktop-module--skew--Hionk";
export var skewBottom = "ProjectHighlighDesktop-module--skewBottom--TbuBx";
export var content = "ProjectHighlighDesktop-module--content--igvaB";
export var detail = "ProjectHighlighDesktop-module--detail--2zcwV";
export var left = "ProjectHighlighDesktop-module--left--2KN3L";
export var inner = "ProjectHighlighDesktop-module--inner--3Z9UC";
export var our = "ProjectHighlighDesktop-module--our--17tek";
export var img = "ProjectHighlighDesktop-module--img--3cfq5";
export var textBox = "ProjectHighlighDesktop-module--textBox--1nZQN";
export var right = "ProjectHighlighDesktop-module--right--kmlax";
export var topRight = "ProjectHighlighDesktop-module--topRight--dKiOR";
export var projectTopRight = "ProjectHighlighDesktop-module--projectTopRight--1AKrD";
export var bottomRight = "ProjectHighlighDesktop-module--bottomRight--3TDE4";