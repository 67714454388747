import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
import MoreIcon from '../icons/more.svg'
import ColumnLines from 'src/components/ColumnLines'
import LinkedinIcon from '../icons/linkedin.svg'
import FacebookIcon from '../icons/facebook.svg'
//@ts-ignore
import * as Styles from './GetInTouch.module.scss'

const GetInTouch = () => {
    const intl = useIntl()
    return (
        <div lang={intl.locale} className={Styles.main}>
            <ColumnLines />
            <div className={Styles.content}>
                <MoreIcon />
                <div className={Styles.contact}>
                    <h2
                        lang={intl.locale}
                    >{intl.formatMessage({ id: 'GET IN TOUCH' })}</h2>
                    <div className={Styles.info}>
                        <table>
                            <tr>
                                <td><p lang={intl.locale}>{intl.formatMessage({ id: 'Phone:' })}</p></td>
                                <td><p>+852 2399 0113</p></td>
                            </tr>
                            <tr>
                                <td><p lang={intl.locale}>{intl.formatMessage({ id: 'Fax:' })}</p></td>
                                <td><p>+852 2300 0155</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <p lang={intl.locale}>{intl.formatMessage({ id: 'E-Mail:' })}</p>
                                </td>
                                <td>
                                    <a href='mailto:info@inkalimited.com'>
                                        <u><p>info@inkalimited.com</p></u>
                                    </a>
                                </td>

                            </tr>
                        </table>
                        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'address' }) }} />

                        {/* <div className={Styles.social}>
                            <a href={''}>
                                <FacebookIcon />
                            </a>
                            <LinkedinIcon />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetInTouch

