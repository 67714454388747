import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
//@ts-ignore
import * as  Styles from './Footer.module.scss'

const Footer = () => {
    const intl = useIntl()
    return (
        <div lang={intl.locale}  className={Styles.main}>
            <div className={Styles.left}>
                <div className={Styles.logoBox}>
                    <div  className={Styles.logo}>INKA</div>
                    <h3>
                        盈力工程有限公司
                    </h3>
                </div>
                <h3>
                    {`Copyright©${new Date().getFullYear()} INKA Limited Company, All Rights Reserved.`}
                </h3>
            </div>
            <div className={Styles.right}>
                Powered by Instelar Limited
            </div>
        </div>
    )
}

export default Footer
