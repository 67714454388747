import React from 'react'

//@ts-ignore
import * as Styles from './ColumnLines.module.scss'

const ColumnLines = () => {
    return (
        <div className={Styles.column}>
            <div />
            <div className={Styles.line} />
            <div className={Styles.line} />
            <div className={Styles.line} />
            <div className={Styles.line} />
            <div className={Styles.line} />
            <div className={Styles.line} />
            <div />
        </div>
    )
}

export default ColumnLines
